import React from "react"
import Layout from "../../components/Layout"
import uwuImg from "../../../static/album-3.png"

const discography = () => {
  return (
    <Layout>
      <main className={"discography-image w-100 flex-center-rw"}>
        <h1>DISCOGRAPHY</h1>
      </main>
      <section className={"discography-content w-100 flex-center-cl"}>
        <h2>Our Discography</h2>
        <div className={"discography-items"}>
          <div>
            <img src={uwuImg} alt="aykunna uwu" />
            <div>
              <h3>UWU</h3>
              <p>A little desc for the song</p>
              <div className={"watch-on-yt flex-center-rw"}>
                <a href="#" target="_blank">Watch on YouTube</a>
              </div>
            </div>
          </div>
          <div>
            <img src={uwuImg} alt="aykunna uwu" />
            <div>
              <h3>UWU</h3>
              <p>A little desc for the song</p>
              <div className={"watch-on-yt flex-center-rw"}>
                <a href="#" target="_blank">Watch on YouTube</a>
              </div>
            </div>
          </div>
          <div>
            <img src={uwuImg} alt="aykunna uwu" />
            <div>
              <h3>UWU</h3>
              <p>A little desc for the song</p>
              <div className={"watch-on-yt flex-center-rw"}>
                <a href="#" target="_blank">Watch on YouTube</a>
              </div>
            </div>
          </div>
          <div>
            <img src={uwuImg} alt="aykunna uwu" />
            <div>
              <h3>UWU</h3>
              <p>A little desc for the song</p>
              <div className={"watch-on-yt flex-center-rw"}>
                <a href="#" target="_blank">Watch on YouTube</a>
              </div>
            </div>
          </div>
          <div>
            <img src={uwuImg} alt="aykunna uwu" />
            <div>
              <h3>UWU</h3>
              <p>A little desc for the song</p>
              <div className={"watch-on-yt flex-center-rw"}>
                <a href="#" target="_blank">Watch on YouTube</a>
              </div>
            </div>
          </div>
          <div>
            <img src={uwuImg} alt="aykunna uwu" />
            <div>
              <h3>UWU</h3>
              <p>A little desc for the song</p>
              <div className={"watch-on-yt flex-center-rw"}>
                <a href="#" target="_blank">Watch on YouTube</a>
              </div>
            </div>
          </div>
          <div>
            <img src={uwuImg} alt="aykunna uwu" />
            <div>
              <h3>UWU</h3>
              <p>A little desc for the song</p>
              <div className={"watch-on-yt flex-center-rw"}>
                <a href="#" target="_blank">Watch on YouTube</a>
              </div>
            </div>
          </div>
          <div>
            <img src={uwuImg} alt="aykunna uwu" />
            <div>
              <h3>UWU</h3>
              <p>A little desc for the song</p>
              <div className={"watch-on-yt flex-center-rw"}>
                <a href="#" target="_blank">Watch on YouTube</a>
              </div>
            </div>
          </div>
          <div>
            <img src={uwuImg} alt="aykunna uwu" />
            <div>
              <h3>UWU</h3>
              <p>A little desc for the song</p>
              <div className={"watch-on-yt flex-center-rw"}>
                <a href="#" target="_blank">Watch on YouTube</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default discography